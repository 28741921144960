import React from "react";
import Nav from "./Components/nav/Nav";
import Header from "./Components/header/Header";
import About from "./Components/about/About";
import Projects from "./Components/projects/Projects";
import Contact from "./Components/contact/Contact";
import Skills from "./Components/skills/Skills";

export const App = () => {
  return (
    <>
      <Nav />
      <Header />
      <Skills />
      <Projects />
      <About />
      <Contact />
    </>
  );
};

export default App;