import React from "react";
import "./projects.css";

import Proj1 from "../../assets/hotrodcameras-img.jpg";
import Proj2 from "../../assets/hrc-designs.jpg";
import Proj3 from "../../assets/appleannies-img.jpg";
import Proj4 from "../../assets/meeting-cost-calculator.jpg";
import Proj5 from "../../assets/integrations.jpg";
import Proj6 from "../../assets/gutenberg-blocks.png";

const data = [
  {
    id: 1,
    image: Proj1,
    title: "HotRodCameras - Site",
    description:
      "Led the development of the HotRodCameras Shopify E-Commerce site, primarily utilizing Shopify Liquid and JavaScript. *Owned by Sultans*",
    view: "https://hotrodcameras.com/",
    type: "sultans",
    // more: "",
  },
  {
    id: 2,
    image: Proj2,
    title: "HotRodCameras - Designs",
    description:
      "The Figma designs for HotRodCameras' Shopify E-Commerce site - Created primarily utilizing Figma & Adobe Photoshop. *Owned by Sultans*",
    view: "https://www.figma.com/design/OoQKj1pRq5yl8wLCfS2Nad/HotRodCameras-Designs?node-id=0-1&t=F5npKg71OvxIHJEf-1",
    type: "sultans",
    // more: "",
  },
  {
    id: 3,
    image: Proj3,
    title: "Apple Annie's - Site",
    description:
      "Led the development of the Apple Annie's Shopify E-Commerce site, primarily utilizing Shopify Liquid and JavaScript. *Owned by Sultans*",
    view: "https://appleanniesbakeshop.com/",
    type: "sultans",
    // more: "",
  },
    {
    id: 4,
    image: Proj4,
    title: "Meeting Cost Calculator",
    description:
      "A meeting cost calculator tool for users to understand how much money they are spending daily, weekly, annually. *Owned by Fellow*",
    view: "https://fellow.app/tools/meeting-cost-calculator/",
    type: "fellow",
    // more: "",
  },
  {
    id: 5,
    image: Proj5,
    title: "Integrations Page",
    description:
      "A custom coded WordPress archive and taxonomy page used to display the integrations all included with Fellow. *Owned by Fellow*",
    view: "https://fellow.app/integrations/",
    type: "fellow",
    // more: "",
  },
  {
    id: 6,
    image: Proj6,
    title: "WordPress Gutenberg Block",
    description:
      "A custom coded WordPress Gutenberg block utilized throughout the site, and configurable within the WP Admin. *Owned by Fellow*",
    view: "https://fellow.app/compare/fellow-vs-google-docs/",
    type: "fellow",
    // more: "",
  },
];

export const Projects = () => {
  return (
    <section id="projects">
      <div className="projects__header">
        <h5>Projects</h5>
        <h2>
          <span className="tag">&lt;h2&gt;</span> Portfolio{" "}
          <span className="tag">&lt;/h2&gt;</span>
        </h2>
      </div>

      <div className="container project__container">
        <span className="project__item-text sultans-company">SULTANS</span>
        <span className="project__item-text fellow-company">FELLOW</span>
        {data.map(({ id, image, title, description, view, type, more }, index) => {
          return (
            <article className={`project__item ${type === "fellow" ? "fellow-company" : "sultans-company"}`}>
              <a href={view} target="_blank" rel="noreferrer">
                <div className="project__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <h4>{description}</h4>
                <div className="project__item-cta">
                  <a
                    href={view}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    Learn More
                  </a>
                </div>
              </a>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Projects;
