import React from "react";
import "./header.css";
// import CTA from "./CTA";
// import Illustration from "../../assets/aimee-illustration.png";
import HeaderSocials from "./HeaderSocials";

const Header = () => {
  return (
    <header>
      <div className="header__overlay"></div>
      <div className="container header__container vertical-off-center ">
        <div className="header__content">
          <div className="heading__name">
            <h1>
              <span className="color-primary">Aimée</span>
              <span className="name__second-row color-secondary">Massé</span>
            </h1>
          </div>
          <div className="position__text">
            <p className="indent color-primary">
              <span className="text_1">Front-End Developer & Designer</span>
            </p>
          </div>
        </div>
        <HeaderSocials />
      </div>
    </header>
  );
};

export default Header;
