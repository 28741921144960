import React from "react";
import "./about.css";

import { FaGraduationCap, FaCode } from "react-icons/fa";
import { BsFileEarmarkPerson } from "react-icons/bs";
import Portrait from "../../assets/about-me-photo.png";

const About = () => {
  return (
    <section id="about">
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={Portrait} alt="" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__header-content">
            <h5>Get to Know</h5>
            <h2>
              <span className="tag">&lt;h2&gt;</span> About Me{" "}
              <span className="tag">&lt;/h2&gt;</span>
            </h2>
          </div>
          <div className="about__cards">
            <article className="about__card">
              <FaCode className="about__icon" />
              <h5>Experience</h5>
              <small>3+ Years Working</small>
            </article>
            <article className="about__card">
              <FaGraduationCap className="about__icon" />
              <h5>Education</h5>
              <small>New Media Design &amp; Web Dev Diploma</small>
            </article>
            <article className="about__card">
              <BsFileEarmarkPerson className="about__icon" />
              <h5>Previous Role</h5>
              <small>Front-End Engineer @ Sultans</small>
            </article>
          </div>

          <p className="about__text">
            Hello! I’m Aimée, and I live in Regina, Saskatchewan. I completed my diploma in New Media Design and Web Development at BCIT in August 2021. When I'm not coding or designing, you can usually find me at the gym, traveling, singing, or gaming. This year, I had the chance to visit Chile, Argentina, Brazil, and Greece! After each trip, I dedicate some time to creating a video that highlights all the amazing experiences. Curious to see? <a className="video_link" href="https://youtu.be/e6O4pYiEkQs" target="_blank" rel="noopener noreferrer">Check out my latest video!</a> My favorite part of the journey was <a className="video_link" href="https://youtu.be/e6O4pYiEkQs?si=eWZ3U1ehpCrsZ8c4&t=170" target="_blank" rel="noopener noreferrer">Iguazu Falls</a>.
          </p>
          <p className="about__text">
            In my previous position, I worked at Sultans as a front-end engineer, creating e-commerce websites for clients using Shopify and Liquid.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
