import React, { useState } from "react";
import "./nav.css";
import { TbMessages } from "react-icons/tb"; 
import Logo from "../../assets/aimee_logo.png"; 

export const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");

  const handleMailTo = () => {
    window.location.href = "mailto:ajmasse68@gmail.com";
  };

  return (
    <nav className="navbar">

      {/* Navigation Links */}
      <ul className="navbar-links">
        {/* Logo */}
        <li className="navbar-logo">
          <img className="aimee_logo" src={Logo} alt="Logo" />
        </li>
        <li>
          <a
            href="#about"
            className={activeNav === "#about" ? "active" : ""}
            onClick={() => setActiveNav("#about")}
          >
            About Me
          </a>
        </li>
        <li>
          <a
            href="#projects"
            className={activeNav === "#projects" ? "active" : ""}
            onClick={() => setActiveNav("#projects")}
          >
            Portfolio
          </a>
        </li>
        <li>
          <a
            href="#contact"
            className={activeNav === "#contact" ? "active" : ""}
            onClick={() => setActiveNav("#contact")}
          >
            Contact
          </a>
        </li>
      </ul>

      {/* Button */}
      <button className="navbar-button" onClick={handleMailTo}>
        Let's Talk
        <TbMessages className="icon" />
      </button>
    </nav>
  );
};

export default Nav;
